import Vue from 'vue'
import App from './App.vue'
import router from './router'

import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'

import VuetifyMask from "vuetify-mask";

Vue.use(VuetifyMask);
import VuePannellum from 'vue-pannellum'

Vue.component('VPannellum', VuePannellum)
// import vue-panzoom
import panZoom from 'vue-panzoom'

// install plugin
Vue.use(panZoom);
Vue.config.productionTip = false
Vue.use(VueAxios, axios);


Vue.config.productionTip = false

Vue.use(VueAxios, axios)
if (process.env.NODE_ENV === 'development') {
    Vue.axios.defaults.baseURL = 'http://127.0.0.1:83/v1/'
    Vue.axios.defaults.backHost = 'http://127.0.0.1:83/'
} else {
    Vue.axios.defaults.baseURL = window.location.protocol + "//api." + window.location.host + '/v1/'
    Vue.axios.defaults.backHost = window.location.protocol + "//api." + window.location.host + '/'
}
import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js';
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';
import oauth2Afina from './plugins/afinaId.js';
Vue.use(auth, {
    plugins: {
        http: axios, // Axios
        router: router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios,
        router: driverRouterVueRouter,
        oauth2: {
            afina: oauth2Afina,
        }
    },
    options: {
        authRedirect: '/login',
        notFoundRedirect: '/',
    }
});

const moment = require('moment')
require('moment/locale/ru')
Vue.use(require('vue-moment'), {
  moment
})

new Vue({
    router,

    vuetify,
    render: h => h(App)
}).$mount('#app')
